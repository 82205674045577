export default {
    application: {
        version: '0.0.58',
        defaultURL: 'https://backoff.askme-app.fr/',
        resourcesURL: 'https://resources.askme-app.fr/'
        //defaultURL: 'https://ask.ipcvt.org/'
        //defaultURL: 'http://localhost:8888/'
    },
    mapbox: {
        API_KEY: 'pk.eyJ1IjoiZnNnYXJkIiwiYSI6ImNrODVtanhmcTA3Y3YzZ3BqbXBpaXcwY2EifQ.Q9J9kR1nowA7GThkE7SCWw'
    },
    firebase: {
        API_KEY: 'AIzaSyBvYjtSkUrUkNISMqq7oWk4QwO5vAMdHKQ'
    },
    objects: {
        REQUEST: 1,
        DOSSIER: 2
    },
    pushActions: {
        OPEN_REQUEST_DETAILS: 1,
        OPEN_DOSSIER_VEHICLE_MILEAGE: 2,
        OPEN_DOSSIER_VEHICLE_AUDIT: 3
    },
    activity: {
        TYPE_NEW_MESSAGE: 1,
        TYPE_APPOINTMENT_CONFIRMED: 2,
        TYPE_APPOINTMENT_REFUSED: 3,
        TYPE_NEW_DOCUMENT: 4,
        TYPE_NEW_REQUEST: 5,
        TYPE_CHANGE_STATUS: 6,
        TYPE_NEW_APPOINTMENT: 7,
        TYPE_APPOINTMENT_CANCELED: 8,    
        TYPE_APPOINTMENT_CANCEL_CONFIRMED: 9,
        TYPE_APPOINTMENT_BOOKED: 10
    },
    status: {
        STATUS_CREATE: 0,
        STATUS_RECEIVED: 10,
        STATUS_RECALLED: 15,
        STATUS_PROCESSING: 20,
        STATUS_STANDBY: 30,
        STATUS_WAITING_USER_INFOS: 40,
        STATUS_WAITING_PROVIDER_INFOS: 50,
        STATUS_COMPLETED: 60,
        STATUS_CANCELED: 70,
        STATUS_REFUSED: 80,
        STATUS_ARCHIVED: 90       
    },
    dossiers: {
        TYPE_NONE: 0,
        TYPE_APPOINTMENT: 1,
        TYPE_ASSISTANCE: 2,
        TYPE_CLAIM: 3,
        TYPE_VEHICLE_MILEAGE: 4,
        TYPE_VEHICLE_AUDIT: 5
    },
    assistances: {
        TYPE_ACCIDENT: 1,
        TYPE_BREAKDOWN: 2,
        TYPE_BROKEN_GLASS: 3,
        TYPE_TIRES: 4,
        TYPE_THEFT: 5,
        TYPE_OTHER: 99
    },
    providers: {
        TYPE_FUEL_COMPANY: 1,
        TYPE_GARAGE: 2,
        TYPE_RENTER: 3,
        TYPE_MAKER: 4,
        TYPE_INSURANCE: 5,
        TYPE_ASSISTANCE: 6,
        TYPE_TIRES_MAKER: 7
    },
    leases: {
        TYPE_PURCHASE: 0,
        TYPE_LLD: 1,
        TYPE_LOA: 2,
        TYPE_LCD: 3,
        TYPE_LMD: 4,
        TYPE_INSURANCE: 5,
        TYPE_ASSISTANCE: 6
    },  
    vehicleServices: {
        SERVICE_OVERHAUL: 1,
        SERVICE_TIRES: 2,
        SERVICE_BRAKEPADS: 3,
        SERVICE_MISC: 4,
        SERVICE_TECHNICAL_CONTROL: 5
    },
    vehicleServicesStatus: {
        STATUS_TODO: 0,
        STATUS_SCHEDULED: 10,
        STATUS_PROCESSING: 20,
        STATUS_COMPLETED: 30,
        STATUS_CANCELED: 99,
    },
    colors: {
        GRAY: "#8e8e93",
        BLUE: "#147efb",
        RED: "#fc3d39",
        GREEN: "#53d769",
        OVERHAUL: "#00cc99",
        TIRES: "#9966ff",
        BREAKPADS: "#ffa64d",
        MAINTENANCE: "#3399ff",
        HOME: "#66ccff",
        WORK: "#ff99ff",
        OTHER_LOCATION: "#ffcc00",
        MY_POSITION: "#53d769",
        STATUS_NEW: "#e014fb",
        STATUS_RECEIVED: "#147efb",
        STATUS_RECALLED: "#fd9426",
        STATUS_PROCESSING: "#5fc9f8",
        STATUS_WAITING: "#fd9426",
        STATUS_WAITING_INFOS: "#fd9426",
        STATUS_WAINTING_PROVIDER_INFOS: "#fd9426",
        STATUS_COMPLETED: "#53d769",
        STATUS_CANCELED: "#8e8e93",
        STATUS_ARCHIVED: "#8e8e93",
        STATUS_REFUSED: "#fc3158",
        STATUS_TODO: "#e014fb",
        STATUS_SCHEDULED: "#147efb"
    },
    users: {
        USER_ROLE_ADMIN: 0,
        USER_ROLE_MANAGER: 1,
        USER_ROLE_COLLABORATOR: 2
    },
    vehicles: {
        UNDER_ROLLING_LAW_PER_CENT: 75,
        UPPER_ROLLING_LAW_PER_CENT: 125,    
        TIRES_TYPE_SUMMER: 0,
        TIRES_TYPE_WINTER: 1,
        TIRES_TYPE_ALL_SEASONS: 2,    
    },
    vehicleModels: {
        CATEGORIES: [
            {id: 1, label: "Berline"},
            {id: 2, label: "Break"},
            {id: 3, label: "Coupé"},
            {id: 4, label: "SUV"},
            {id: 5, label: "Utilitaire"},
            {id: 6, label: "Cabriolet"}
        ],
        FAMILIES: [
            {id: 1, label: "VP"},
            {id: 2, label: "VU"}        
        ]
    },
    vehicleModifications: {
        ENERGIES: [
            {id: 0, label: 'Diesel'},
            {id: 1, label: 'Essence'},
            {id: 2, label: 'Ethanol'},
            {id: 3, label: 'Electrique'},
            {id: 4, label: 'Hybride Diesel'},
            {id: 5, label: 'Hybride Essence'},
            {id: 6, label: 'Gaz naturel'},
            {id: 7, label: 'Hybride Diesel Rechargeable'},
            {id: 8, label: 'Hybride Essence Rechargeable'},
          ]
    }

}